import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Link from 'next/link';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import { LanguageKey } from '../types/common';
import { getRoute } from '../utils/languages';
import { Button } from 'legacy-ui';
import { Heading } from 'legacy-ui';
import { Text } from 'legacy-ui';
import { useWhiteLabel } from '../providers/WhiteLabelProvider';

export const NoListMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 600px;
  > div:last-of-type {
    margin-top: 44px;
  }
`;

export const AirAbove = styled.div`
  margin-top: 32px;
`;

export const TextWrapper = styled.div`
  margin-top: 32px;
  @media only screen and (max-width: ${(props) => props.theme.breakpoint.sm}) {
    > p {
      font-size: 14px;
    }
  }
`;

interface NoListMessageProps {
  icon: IconDefinition;
  headingText: string;
  infoText: string;
  buttonText: string;
  buttonLink?: string;
}

const NoListMessage = ({ icon, headingText, infoText, buttonText, buttonLink = '/search' }: NoListMessageProps) => {
  const whiteLabel = useWhiteLabel();

  const { locale } = useRouter();
  return (
    <NoListMessageWrapper>
      <FontAwesomeIcon icon={icon} size="8x" />
      <AirAbove>
        <Heading size="r">{headingText}</Heading>
      </AirAbove>
      <TextWrapper>
        <Text size="sm">{infoText}</Text>
      </TextWrapper>
      {!whiteLabel.restrictedPages.includes(buttonLink.replace('/', '')) && (
        <AirAbove>
          <Link href={getRoute(buttonLink, locale as LanguageKey)} passHref>
            <a>
              <Button color="primary" leftIcon={faSearch}>
                {buttonText}
              </Button>
            </a>
          </Link>
        </AirAbove>
      )}
    </NoListMessageWrapper>
  );
};

export default NoListMessage;
